













import { Component, Vue } from 'vue-property-decorator';


interface IItemQuery extends Record<string, any> {}

interface IItem extends Record<string, any>{
    // id: string | number;
    href: string;
    i18n?: string;
    name?: string;
    query?: IItemQuery;
}


@Component
export default class BudgetRequestLinkList extends Vue {
    private get menuBudgetRequest(): IItem[] {
        return [
            { id: 100, name: 'Cводный расчёт расходов', href: '/budget-sum-calc' },
            { id: 101, name: 'Бюджетные программы', href: '/budget-program' },
            // { id: 1, name: 'Форма 01-111', href: '/form01-111' },
            // { id: 2, name: 'Форма 02-111', href: '/form02-111' },
            { id: 3, name: 'Форма 01-123', href: '/form01-123' },
            { id: 4, name: 'Форма 02-123', href: '/form02-123' },
            { id: 5, name: 'Форма 01-136', href: '/form01-136' },
            { id: 6, name: 'Форма 01-139', href: '/form01-139' },
            { id: 7, name: 'Форма 01-144', href: '/form01-144' },
            { id: 8, name: 'Форма 02-144', href: '/form02-144' },
            { id: 9, name: 'Форма 01-149', href: '/form01-149' },
            { id: 10, name: 'Форма 02-149', href: '/form02-149' },
            { id: 11, name: 'Форма 03-149', href: '/form03-149' },
            { id: 12, name: 'Форма 01-151', href: '/form01-151' },
            { id: 13, name: 'Форма 02-151', href: '/form02-151' },
            { id: 14, name: 'Форма 03-151', href: '/form03-151' },
            { id: 15, name: 'Форма 04-151', href: '/form04-151' },
            { id: 16, name: 'Форма 01-152', href: '/form01-152' },
            { id: 17, name: 'Форма 01-153', href: '/form01-153' },
            { id: 18, name: 'Форма 01-159', href: '/form01-159' },
            { id: 19, name: 'Форма 02-159', href: '/form02-159' },
            { id: 20, name: 'Форма 02-159 (Дороги)', href: '/form02-159-1' },
            { id: 21, name: 'Форма 02-159 (339)', href: '/form02-159-2' },
            { id: 22, name: 'Форма 03-159', href: '/form03-159' },
            { id: 23, name: 'Форма 01-161', href: '/form01-161' },
            { id: 24, name: 'Форма 01-169', href: '/form01-169' },
            { id: 25, name: 'Форма 01-413', href: '/form01-413' },
            { id: 26, name: 'Форма 01-414', href: '/form01-414' },
            { id: 27, name: 'Форма 01-416', href: '/form01-416' },
            { id: 29, name: 'Форма 01-324', href: '/form01-324' }
        ];
    }

    private get items(): IItem[] {
        const staffingTableItem: IItem = {
            id: 'staffingTable',
            href: '/staffing-table/data',
            i18n: 'app.links.staffingTable'
        };

        return [staffingTableItem].concat(this.menuBudgetRequest);
    }

    private getItemHref(item: IItem): string {
        return this.$router.resolve(
            {
                path: item.href,
                query: item.query
            },
            undefined,
            false
        ).href;
    }
}
